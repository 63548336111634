export let ENVType = process.env.ENVTYPE; 
export const AlertTime = 4000;
// export let Activity_Notification_Email = "invite@notify.optimiser.com";
// export let OperaEndpoint = window.location.origin + '/opera';
// export let envWiseKioskUrl = '';
// export let bookAppDomain = '';
// export let readModeEditor = {};
// switch (window.location.origin.split("://")[1].split(".")[0]) {
//     case "platform":
//     case "prod":
//     case "prod1":
//     case "prod2":
//         // ENVType = "prod";
//         // Activity_Notification_Email = "invite@notify.optimiser.com";
//         // OperaEndpoint = "https://operaapi.optimiser.com/opera";
//         // envWiseKioskUrl = `https://kiosk.optimiser.com`;
//         // bookAppDomain = 'https://calendar.optimiser.com';
//         readModeEditor = { readOnly: "nocursor" };
//         break;
//     case "sandbox":
//     case "sand":
//         // ENVType = "sand";
//         // Activity_Notification_Email = "invite@notifysand.optimiser.com";
//         // envWiseKioskUrl = `https://sand.kiosk.boit.com`;
//         // bookAppDomain = 'https://calendar.sand.boit.uk';
//         readModeEditor = { readOnly: "nocursor" };
//         break;
//     case "test":
//         // ENVType = "test";
//         // Activity_Notification_Email = "invite@notifytest.optimiser.com";
//         // envWiseKioskUrl = `https://test.kiosk.boit.com`;
//         // bookAppDomain = 'https://calendar.test.boit.uk';
//         break;
//     case "localhost":
//         // ENVType = "dev";
//         // Activity_Notification_Email = "invite@notifydev.optimiser.com";
//         // envWiseKioskUrl = `https://dev.kiosk.boit.com`;
//         // bookAppDomain = 'http://localhost:9001';
//         break;
//     default:
//         // ENVType = "dev"
//         // Activity_Notification_Email = "invite@notifydev.optimiser.com";
//         // envWiseKioskUrl = `https://dev.kiosk.boit.com`;
//         // bookAppDomain = 'https://calendar.dev.boit.uk';
//         break;
// }

const _constantClient = {

    EMAIL_VALIDATE_REGEX: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
    TEMPLATE_PUBLIC_IMAGE_URL: '../images/marketing-template-image/',
    TEMPLATE_SERVER_IMAGE_URL: `${process.env.APP_CDN}/`,
    SITE_URL: window.location.origin,
    OPERA_ENDPOINT: process.env.OPERA_ENDPOINT,
    CDN: process.env.APP_CDN,
    FilterOperators: [{
        'Key': 'CONTAINS',
        'DisplayName': 'contains'
    },
    {
        'Key': 'NOT_CONTAINS',
        'DisplayName': 'not contains'
    },
    {
        'Key': 'EQUAL',
        'DisplayName': 'equals'
    },
    {
        'Key': 'NOT_EQUAL',
        'DisplayName': 'not equal to'
    },
    {
        'Key': 'GREATER_THAN',
        'DisplayName': 'greater than'
    },
    {
        'Key': 'LESS_THAN',
        'DisplayName': 'less than'
    },
    {
        'Key': 'GREATER_OR_EQUAL',
        'DisplayName': 'greater or equal to'
    },
    {
        'Key': 'LESS_OR_EQUAL',
        'DisplayName': 'less or equal to'
    },
    {
        'Key': 'STARTS_WITH',
        'DisplayName': 'start with'
    },
    {
        'Key': 'END_WITH',
        'DisplayName': 'end with'
    }
    ],

    Months: [{
        'Key': 1,
        'Index': 0,
        'Name': 'January',
        'SortName': 'Jan'
    },
    {
        'Key': 2,
        'Index': 1,
        'Name': 'February',
        'SortName': 'Feb'
    },
    {
        'Key': 3,
        'Index': 2,
        'Name': 'March',
        'SortName': 'Mar'
    },
    {
        'Key': 4,
        'Index': 3,
        'Name': 'April',
        'SortName': 'Apr'
    },
    {
        'Key': 5,
        'Index': 4,
        'Name': 'May',
        'SortName': 'May'
    },
    {
        'Key': 6,
        'Index': 5,
        'Name': 'June',
        'SortName': 'Jun'
    },
    {
        'Key': 7,
        'Index': 6,
        'Name': 'July',
        'SortName': 'Jul'
    },
    {
        'Key': 8,
        'Index': 7,
        'Name': 'August',
        'SortName': 'Aug'
    },
    {
        'Key': 9,
        'Index': 8,
        'Name': 'September',
        'SortName': 'Sep'
    },
    {
        'Key': 10,
        'Index': 9,
        'Name': 'October',
        'SortName': 'Oct'
    },
    {
        'Key': 11,
        'Index': 10,
        'Name': 'November',
        'SortName': 'Nov'
    },
    {
        'Key': 12,
        'Index': 11,
        'Name': 'December',
        'SortName': 'Dec'
    }
    ],

    LookupAlias: '_LookupData',
    SEARCHVALUEALIAS: '_SearchValue',
    LookupTypeFields: ["lookup", "multilookup", "dropdown", "multiselect", "file", "image"],

    SEND_OUT_STATUS: [{
        'StatusID': 1,
        'Value': 'Draft'
    },
    {
        'StatusID': 2,
        'Value': 'Scheduled'
    },
    {
        'StatusID': 3,
        'Value': 'Sending'
    },
    {
        'StatusID': 4,
        'Value': 'Sent'
    }
    ],
    URL_INVALID_MSG: 'Please enter valid url !',
    FOLDER_NAME_EMPTY_MSG: 'Please enter valid Folder name.',
    FOLDER_NAME_EXISTS_MSG: 'Folder name already exists.',
    FOLDER_INSERT_MSG: 'Folder name inserted successfully.',
    ERROR_MSG: 'Something went wrong.',

    NOTIFICATION_KEY: process.env.NOTIFICATION_KEY,
    /*USER STATUS ENUM*/
    USER_STATUS: {
        ACTIVE: 'Active',
        BLOCKED: 'Blocked',
        REPLACE: "Replace"
    },
    LIST_SCHEMA_TYPE: {
        MARKETING_REGISTERED_EMAIL: 'MarketingRegisteredEmail',
        MARKETING_REGISTERED_DOMAIN: 'MarketingRegisteredDomain'
    },

    /******* Calendar Constants Start*/
    BACKDATEDISALLOW: "Back Date Activity is not allowed to be deleted.",
    /******* Calendat Constants End */
    ActivityNotificationEmail: process.env.ACTIVITY_NOTIFICATION_EMAIL,

    InitialSyncStatus: {
        READY: "1",
        IN_PROCESS: "2",
        SUCCESS: "3",
        FAIL: "4"
    },
    OperaPropertyStatus: {
        Active: "0",
        Suspended: "1"
    },
    /*Store App names List */
    STORE_APP_NAMES: {
        OPERA_PMS: "OperaPMS"
    },
    USER_STATUS: {
        ACTIVE: 'Active',
        BLOCKED: 'Blocked',
        REPLACE: "Replace"
    },
    // BACKUP_STATUS: { //Comment this code for QPC-9000
    //     PROCESSING: 'InProgress',
    //     ERRORED: 'Errored',
    //     SCHEDULED: 'Scheduled',
    //     COMPLETED: 'Completed'
    // },
    // RESTORE_STATUS: {
    //     PROCESSING: 'InProgress',
    //     ERRORED: 'Errored',
    //     COMPLETED: 'Completed'
    // },
    Fiscal_Setting_Update_Msg: 'You are updating FISCAL YEAR setting! This will result change your forecasting.',
    DateFilterOperatorsInAdvanceFilter: [{
        'Key': 'custom',
        'DisplayName': 'Custom'
    },
    {
        'Key': 'yesterday',
        'DisplayName': 'Yesterday'
    },
    {
        'Key': 'today',
        'DisplayName': 'Today'
    },
    {
        'Key': 'tomorrow',
        'DisplayName': 'Tomorrow'
    },
    {
        'Key': 'last-7-days',
        'DisplayName': 'Last 7 Days'
    },
    {
        'Key': 'last-30-days',
        'DisplayName': 'Last 30 Days'
    },
    {
        'Key': 'last-60-days',
        'DisplayName': 'Last 60 Days'
    },
    {
        'Key': 'last-90-days',
        'DisplayName': 'Last 90 Days'
    },
    {
        'Key': 'last-120-days',
        'DisplayName': 'Last 120 Days'
    },
    {
        'Key': 'next-7-days',
        'DisplayName': 'Next 7 Days'
    },
    {
        'Key': 'next-30-days',
        'DisplayName': 'Next 30 Days'
    },
    {
        'Key': 'next-60-days',
        'DisplayName': 'Next 60 Days'
    },
    {
        'Key': 'next-90-days',
        'DisplayName': 'Next 90 Days'
    },
    {
        'Key': 'next-120-days',
        'DisplayName': 'Next 120 Days'
    },
    {
        'Key': 'last-week',
        'DisplayName': 'Last Week'
    },
    {
        'Key': 'this-week',
        'DisplayName': 'This Week'
    },
    {
        'Key': 'next-week',
        'DisplayName': 'Next Week'
    },
    {
        'Key': 'last-month',
        'DisplayName': 'Last Month'
    },
    {
        'Key': 'this-month',
        'DisplayName': 'This Month'
    },
    {
        'Key': 'next-month',
        'DisplayName': 'Next Month'
    },
    {
        'Key': 'last-quarter',
        'DisplayName': 'Last Quarter'
    },
    {
        'Key': 'this-quarter',
        'DisplayName': 'This Quarter'
    },
    {
        'Key': 'next-quarter',
        'DisplayName': 'Next Quarter'
    },
    {
        'Key': 'last-fiscal-quarter',
        'DisplayName': 'Last Fiscal Quarter'
    },
    {
        'Key': 'this-fiscal-quarter',
        'DisplayName': 'This Fiscal Quarter'
    },
    {
        'Key': 'next-fiscal-quarter',
        'DisplayName': 'Next Fiscal Quarter'
    },
    {
        'Key': 'last-year',
        'DisplayName': 'Last Year'
    },
    {
        'Key': 'this-year',
        'DisplayName': 'This Year'
    },
    {
        'Key': 'next-year',
        'DisplayName': 'Next Year'
    },
    {
        'Key': 'last-fiscal-year',
        'DisplayName': 'Last Fiscal Year'
    },
    {
        'Key': 'this-fiscal-year',
        'DisplayName': 'This Fiscal Year'
    },
    {
        'Key': 'next-fiscal-year',
        'DisplayName': 'Next Fiscal Year'
    },
    {
        'Key': 'current-calendar-year',
        'DisplayName': 'Current Calendar Year'
    },
    {
        'Key': 'previous-calendar-year',
        'DisplayName': 'Previous Calendar Year'
    },
    {
        'Key': 'next-calendar-year',
        'DisplayName': 'Next Calendar Year'
    },
    {
        'Key': 'year-to-date',
        'DisplayName': 'Year to Date'
    }
    ],
    DateFilterOperators: [{
        'Key': 'BETWEEN',
        'DisplayName': 'Custom'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Yesterday'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Today'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Tomorrow'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Last 7 Days'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Last 30 Days'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Last 60 Days'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Last 90 Days'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Last 120 Days'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next 7 Days'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next 30 Days'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next 60 Days'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next 90 Days'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next 120 Days'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Last Week'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'This Week'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next Week'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Last Month'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'This Month'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next Month'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Last Quarter'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'This Quarter'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next Quarter'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Last Fiscal Quarter'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'This Fiscal Quarter'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next Fiscal Quarter'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Last Year'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'This Year'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next Year'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Last Fiscal Year'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'This Fiscal Year'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next Fiscal Year'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Current Calendar Year'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Previous Calendar Year'
    },
    {
        'Key': 'BETWEEN',
        'DisplayName': 'Next Calendar Year'
    },
    { // Year to date added  By hanisha 4/04/22 
        'Key': 'BETWEEN',
        'DisplayName': 'Year to Date'
    }
    ],
    CompareToFields: [{
        'Key': 'Custom',
        'DisplayName': 'Custom'
    },
    {
        'Key': 'PreviousPeriod',
        'DisplayName': 'Previous Period'
    },
    {
        'Key': 'PreviousYear',
        'DisplayName': 'Previous Year'
    }
    ],
    SystemDefineFields: [{
        "Name": "CreatedDate",
        "DisplayName": "Created Date",
        "UIDataType": "datetime",
        "IsDateField": true,
        "ShowInApplications": ["emailintegration", "mobile", "web"]
    },
    {
        "Name": "ModifiedDate",
        "DisplayName": "Last Modified Date",
        "UIDataType": "datetime",
        "IsDateField": true,
        "ShowInApplications": ["emailintegration", "mobile", "web"]
    },
    {
        "Name": "CreatedBy",
        "DisplayName": "Created By",
        "UIDataType": "objectid",
        "IsEmailField": true,
        "LookupObject": "User",
        "ShowInApplications": ["emailintegration", "mobile", "web"]
    },
    {
        "Name": "ModifiedBy",
        "DisplayName": "Last Modified By",
        "UIDataType": "objectid",
        "IsEmailField": true,
        "LookupObject": "User",
        "ShowInApplications": ["emailintegration", "mobile", "web"]
    }
    ],
    SystemDefineFieldsName: ['_id', 'CreatedBy', 'CreatedDate', 'ModifiedBy', 'ModifiedDate'],
    SystemDefineModules: ["TermsCondition"],
    DefaultIncorrectPasswordAttempts: 5,
    DefaultUserActivateMinAfteIncorrectPasswordAttempts: 60,
    DefaultInCorrectOTPAttempts: 5,
    DefaultOTPExpireTime: 5,
    DefaultActivateOTPBlockedUser: 60,
    System_Diagnos_Collection: ['ObjectSchema', 'ListSchema', 'Page', 'MarketingTemplate', 'MySettingEntity', 'ModuleMaster', 'ControlPanelEntity', 'UserProfile', 'City', 'Country', 'User', 'Company'],
    /******* Object Setup Fields Constants Start */
    FieldTypeProperties: {
        autoincrement: ["MinLength", "Format", "StartingNumber"],
        checkbox: ["DefaultValueForChecked", "DefaultValueForUnChecked"],
        date: ["DefaultValueForDate"],
        datetime: ["Interval"],
        decimal: ["DecimalPlace", "IsPercent", "MinValueForDecimal", "MaxValueForDecimal", "DefaultValue"],
        dropdown: ["ListOfValues", "ControlPanel", "Object", "FirstValueAsDefault", "DisplayValuesAlphabetically", "DefaultValueForDropdown", "IsSingleSelectSearch"],
        email: ["MaxLength", "DefaultValue", "IsUnique"],
        file: ["IsMultiple", "FileType", "Type", "CustomMessage"],
        eformula: ["FormulaOutType", "Formula"],
        image: ["FixSizeImage", "Height", "Width"],
        int: ["MinValue", "MaxValue", "DefaultValue"],
        lookup: ["Object", "LookupFields", "SecondaryLabelLookupFields", "LookupFormat", "HasAddButton", "IsExcludeParent", "Limit", "IsHyperlink", "IsDeveloperMade"],
        multilookup: ["Object", "LookupFields", "SecondaryLabelLookupFields", "LookupFormat", "HasAddButton", "IsExcludeParent", "Limit", "IsHyperlink", "IsDeveloperMade"],
        multiselect: ["ListOfValues", "ControlPanel", "Object", "FirstValueAsDefault", "DisplayValuesAlphabetically", "DefaultValueForDropdown", "EnableFilter"],
        phone: ["PreferredCountries", "DefaultValueForPhone"],
        text: ["MaxLength", "DefaultValue", "IsUnique"],
        textarea: ["MaxLength", "DefaultValue"],
        texteditor: ["DefaultValueForEditor"],
        time: ["Interval"],
        url: ["DefaultValue", "IsStrictURL"]
    },
    ObjectLevelFieldAllowedDatatype: ["autoincrement", "email", "int", "text"],
    FieldsAllowedForCoreEdit : ['text', 'textarea', 'texteditor', 'checkbox', 'url', 'int', 'autoincrement', 'email', 'file', 'date', 'datetime', 'time', 'image', 'lookup', 'multilookup', 'decimal', 'phone'],
    KIOSK_URL: process.env.KIOSK_URL,

    /******* Ends */
    ObjectLevelFieldIgnoreList: ["file", "image", "textarea", "texteditor", "location", "objectid", "eformula", "formula", "lookup", "multilookup", "multiselect", "dropdown"],
    FormulaListWithReturnType: {
        'bool': [],
        'date': [],
        'datetime': [],
        'decimal': [],
        'int': [],
        'string': [],
        'time': [],
        'basicPhase': [],
        'allPhase1': [
            "AGGREGATE", "AND", "AVERAGE", "CHAR", "CONCAT", "CONCATENATE", "CONVERT", "COUNT", "COUNTA", "COUNTBLANK",
            "COUNTIF", "COUNTIFS", "DATE", "DATEVALUE", "DAY", "DAYS", "DAYS360", "DECIMAL", "EVEN", "EXACT", "FALSE", "FIND",
            "FREQUENCY", "HOUR", "IF", "IFERROR", "IFNA", "IFS", "INT", "LEFT", "LEN", "MAX", "MID", "MIN", "MINA", "MINUTE",
            "MONTH", "MROUND", "NOT", "NOW", "ODD", "OR", "RIGHT", "ROUND", "SEARCH", "SPLIT", "SUBTOTAL", "SUM", "SUMIF", "SUMIFS",
            "SUMPRODUCT", "TIME", "TIMEVALUE", "TODAY", "UPPER", "WEEKDAY", "WEEKNUM", "WORKDAY", "WORKDAYINTL", "XOR", "YEAR"
        ],
        'all': [
            "ABS", "ACCRINT", "ACOS", "ACOSH", "ACOT", "ACOTH", "AGGREGATE", "AND", "ARABIC", "ASIN",
            "ASINH", "ATAN", "ATAN2", "ATANH", "AVEDEV", "AVERAGE", "AVERAGEA", "AVERAGEIF", "AVERAGEIFS", "BASE",
            "BETADIST", "BETAINV", "BIN2DEC", "BIN2HEX", "BIN2OCT", "BINOMDIST", "BITAND", "BITLSHIFT", "BITOR", "BITRSHIFT",
            "BITXOR", "CEILING", "CEILINGMATH", "CEILINGPRECISE", "CHAR", "CLEAN", "CODE", "COMBIN", "COMBINA", "COMPLEX",
            "CONCAT", "CONCATENATE", "CONVERT", "CORREL", "COS", "COSH", "COT", "COTH", "COUNT", "COUNTA", "COUNTBLANK",
            "COUNTIF", "COUNTIFS", "COUNTUNIQUE", "COVARIANCEP", "COVARIANCES", "CSC", "CSCH", "CUMIPMT", "CUMPRINC", "DATE",
            "DATEVALUE", "DAY", "DAYS", "DAYS360", "DB", "DDB", "DEC2BIN", "DEC2HEX", "DEC2OCT", "DECIMAL",
            "DELTA", "DEVSQ", "DOLLARDE", "DOLLARFR", "EDATE", "EFFECT", "EOMONTH", "ERF", "ERF", "ERFC",
            "ERFC", "EVEN", "EXACT", "EXP", "EXPONDIST", "FACT", "FACTDOUBLE", "false", "FDIST", "FIND",
            "FINV", "FISHER", "FISHERINV", "FLOOR", "FLOORMATH", "FLOORPRECISE", "FORECAST", "FREQUENCY", "FV", "FVSCHEDULE",
            "GAMMA", "GAMMALN", "GAUSS", "GCD", "GEOMEAN", "GESTEP", "GROWTH", "HARMEAN", "HEX2BIN", "HEX2DEC",
            "HEX2OCT", "HOUR", "HYPGEOMDIST", "IF", "IFERROR", "IFNA", "IFS", "IMABS", "IMAGINARY", "IMARGUMENT",
            "IMCONJUGATE", "IMCOS", "IMCOSH", "IMCOT", "IMCSC", "IMCSCH", "IMDIV", "IMEXP", "IMLN", "IMLOG10",
            "IMLOG2", "IMPOWER", "IMPRODUCT", "IMREAL", "IMSEC", "IMSECH", "IMSIN", "IMSINH", "IMSQRT", "IMSUB",
            "IMSUM", "IMTAN", "INT", "INTERCEPT", "IPMT", "IRR", "ISEVEN", "ISOCEILING", "ISODD", "ISOWEEKNUM",
            "ISPMT", "KURT", "LARGE", "LCM", "LEFT", "LEN", "LINEST", "LN", "LOG", "LOG10",
            "LOGNORMDIST", "LOGNORMINV", "LOWER", "MAX", "MAXA", "MEDIAN", "MID", "MIN", "MINA", "MINUTE",
            "MIRR", "MOD", "MODEMULT", "MODESNGL", "MONTH", "MROUND", "MULTINOMIAL", "NETWORKDAYS", "NETWORKDAYSINTL", "NOMINAL",
            "NORMDIST", "NORMINV", "NORMSDIST", "NORMSINV", "NOT", "NOW", "NPER", "NPV", "NUMBERVALUE", "OCT2BIN",
            "OCT2DEC", "OCT2HEX", "ODD", "OR", "PDURATION", "PEARSON", "PERCENTILEEXC", "PERCENTILEINC", "PERCENTRANKEXC", "PERCENTRANKINC",
            "PERMUT", "PERMUTATIONA", "PHI", "PMT", "POISSONDIST", "POWER", "PPMT", "PROB", "PRODUCT", "PROPER",
            "PV", "QUARTILEEXC", "QUARTILEINC", "QUOTIENT", "RADIANS", "RAND", "RANDBETWEEN", "RANKAVG", "RANKEQ", "RATE",
            "REGEXEXTRACT", "REGEXMATCH", "REGEXREPLACE", "REPLACE", "REPT", "RIGHT", "ROMAN", "ROUND", "ROUNDDOWN", "ROUNDUP",
            "RSQ", "SEARCH", "SEC", "SECH", "SECOND", "SIGN", "SIN", "SINH", "SKEW", "SKEWP",
            "SLOPE", "SMALL", "SPLIT", "SQRT", "SQRTPI", "STANDARDIZE", "STDEVA", "STDEVP", "STDEVPA", "STDEVS",
            "STEYX", "SUBSTITUTE", "SUBTOTAL", "SUM", "SUMIF", "SUMIFS", "SUMPRODUCT", "SUMSQ", "SUMX2MY2", "SUMX2PY2",
            "SUMXMY2", "SWITCH", "T", "TAN", "TANH", "TDIST", "TIME", "TIMEVALUE", "TINV", "TODAY",
            "TRIM", "TRIMMEAN", "true", "TRUNC", "UNICHAR", "UNICODE", "UPPER", "VARA", "VARP", "VARPA",
            "VARS", "WEEKDAY", "WEEKNUM", "WEIBULLDIST", "WORKDAY", "WORKDAYINTL", "XOR", "YEAR", "YEARFRAC", "ZTEST"
        ]
    },
    BookAnAppointment_Domain: process.env.APPOINTMENT_DOMAIN,
    CODE_MIRROR_CONFIG: {
        tabSize: 4,
        lineNumbers: true,
        line: true,
        smartIndent: true,
        autoRefresh: true,
        lineWrapping: true,
        extraKeys: {
            "Ctrl-Q": function (cm) { cm.foldCode(cm.getCursor()); },
            "Shift-Tab": function (cm) { cm.autoFormatRange({ line: 0, ch: 0 }, { line: cm.lineCount() }); },
            'Tab': 'emmetExpandAbbreviation',
            'Esc': 'emmetResetAbbreviation',
            'Enter': 'emmetInsertLineBreak',
            "Ctrl-Space": "autocomplete"
        },
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        mode: { name: "javascript", globalVars: true },
        theme: 'monokai',
        viewportMargin: Infinity,
        ...process.env.CODEMIRROR_READONLY_MODE ? { readOnly: "nocursor" } : {}
    },
    PWAMobileTimeInMins: '129600',
    QR_URL: process.env.QR_URL,
    SURVEY_DOMAIN:process.env.SURVEY_DOMAIN,
    SURVEYJS_LICENSE_KEY: "Y2IyYWExMjUtNjAzMC00M2Y5LWE4ODEtZTJhYTJlMGZhY2Q3OzE9MjAyNC0wNy0yOA",

    /*7837 first day of week by gopi kashyap*/
    WeekDayList:[
        {
        'Index': 1,
        'Name': 'Monday',
        'SortName': 'Mon'
       },
       {
        'Index': 2,
        'Name': 'Tuesday',
        'SortName': 'Tue'
       },
       {
        'Index': 3,
        'Name': 'Wednesday',
        'SortName': 'Wed'
       },
       {
        'Index': 4,
        'Name': 'Thursday',
        'SortName': 'Thu'
       }, 
       {
        'Index':5,
        'Name': 'Friday',
        'SortName': 'Fri'
       },
       {
        'Index':6,
        'Name': 'Saturday',
        'SortName': 'Sat'
       },
       {
        'Index':0,
        'Name': 'Sunday',
        'SortName': 'Sun'
       }
    ],
    /*7849 allocate space starting on the first day of the week and the first day of the calendar. by gopi kashyap*/
    FIRST_DAY_SET_ARRAY_INDEX:[
        {
            FDay:1,
            SetDay:[{CFD:0,Index:6},{CFD:1,Index:0},{CFD:2,Index:1}, {CFD:3,Index:2},{CFD:4,Index:3},{CFD:5,Index:4},{CFD:6,Index:5}
            ]
        },
        {
            FDay:2,
            SetDay:[{CFD:0,Index:5},{CFD:1,Index:6},{CFD:2,Index:0}, {CFD:3,Index:1},{CFD:4,Index:2},{CFD:5,Index:3},{CFD:6,Index:4}
            ]
        },
        {
            FDay:3,
            SetDay:[{CFD:0,Index:4},{CFD:1,Index:5},{CFD:2,Index:6}, {CFD:3,Index:0},{CFD:4,Index:1},{CFD:5,Index:2},{CFD:6,Index:3}
            ]
        },
        {
            FDay:4,
            SetDay:[{CFD:0,Index:3},{CFD:1,Index:4},{CFD:2,Index:5}, {CFD:3,Index:6},{CFD:4,Index:0},{CFD:5,Index:1},{CFD:6,Index:2}
            ]
        },
        {
            FDay:5,
            SetDay:[{CFD:0,Index:2},{CFD:1,Index:3},{CFD:2,Index:4}, {CFD:3,Index:5},{CFD:4,Index:6},{CFD:5,Index:0},{CFD:6,Index:1}
            ]
        },
        {
            FDay:6,
            SetDay:[{CFD:0,Index:1},{CFD:1,Index:2},{CFD:2,Index:3}, {CFD:3,Index:4},{CFD:4,Index:5},{CFD:5,Index:6},{CFD:6,Index:0}
            ]
        },
        {
            FDay:0,
            SetDay:[{CFD:0,Index:0},{CFD:1,Index:1},{CFD:2,Index:2}, {CFD:3,Index:3},{CFD:4,Index:4},{CFD:5,Index:5},{CFD:6,Index:6}
            ]
        }
    ],
    GOOGLE_SITE_KEY: process.env.GOOGLE_CAPTCHA_SITE_KEY,
    ACTIVITY_DETAIL: 'ActivityDetail',
    DEFAULT_TIME_GAP_DROPDOWN_MINS: 60,  //Added by Faizan QPC-8498/faizan
    /* Added date formats for the calendar by Yash Mani Jain on 29/05/2024 (QPC-8748) */
    CALENDAR_DATE_FORMATS : [
        'DD-MM-YYYY', 'DD-MMM-YYYY', 'DD-M-YYYY', 'D-M-YYYY',
        'DD/MM/YYYY', 'DD/MMM/YYYY', 'DD/M/YYYY', 'D/M/YYYY'
    ],

    /* Added date formats regex for the calendar by Yash Mani Jain on 29/05/2024 (QPC-8748) */
    CALENDAR_DATE_FORMATS_REGEX : [
        { regex: /^\d{2}-\d{1,2}-\d{4}$/, format: "DD-MM-YYYY" },
        { regex: /^\d{2}-[a-zA-Z]{3}-\d{4}$/, format: "DD-MMM-YYYY" },
        { regex: /^\d{2}-\d{1}-\d{4}$/, format: "DD-M-YYYY" },
        { regex: /^\d{1,2}-\d{1,2}-\d{4}$/, format: "D-M-YYYY" },
        { regex: /^\d{2}\/[a-zA-Z]{3}\/\d{4}$/, format: "DD/MMM/YYYY" },
        { regex: /^\d{2}\/\d{2}\/\d{4}$/, format: "DD/MM/YYYY" },
        { regex: /^\d{2}\/\d{1}\/\d{4}$/, format: "DD/M/YYYY" },
        { regex: /^\d{1,2}\/\d{1,2}\/\d{4}$/, format: "D/M/YYYY" }
    ],
    SOCKET_SERVER_ACCESS_TOKEN : process.env.SOCKET_SERVER_ACCESS_TOKEN,
    SOCKET_SERVER_DOMAIN : process.env.SOCKET_SERVER_DOMAIN,
    EMAIL_INTEGRATION_CONNECTION_STATUS: {
            "connected" : "connected",
            "notconnected" : "notconnected",
            "licenceexpired" : "licenceexpired",
    },
    OPT_LIB_ROOT : process.env.OPT_LIB_ROOT,
    APP_NAME: window.AppGlobals.APP_NAME || 'web',
    TOUCHPOINT_FORMS_EDITOR_URL : process.env.TOUCHPOINT_FORMS_EDITOR_URL,
    /*QPC-9792 Venue status for notification Added by Gopi*/
    ACTIVITY_FIRST_TIME_VENUE_UPDATE:'ActivityFirstTimeVenueUpdate',
    ACTIVITY_VENUE_UPDATE:'ActivityVenueUpdate',
    ACTIVITY_VENUE_REMOVED:'ActivityVenueRemoved'
}
export default _constantClient;