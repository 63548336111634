import './assets/scss/pages/_login.scss';
import './assets/scss/style.scss';
import { PageTarget } from './js/utils/common';
import { RegisterSW } from './sw-register';
import './views/common/root'
import './views/no-auth'
import _constantClient from "./js/utils/constants";

const target = PageTarget();

switch (target) {
    case 'Root':
        //added here bcs it was conflicting with recaptcha library on login
        $('head').append(`<script defer src="${_constantClient.OPT_LIB_ROOT + 'lib/bson.js'}"></script>`);
        $('#optimiser').replaceWith(_views.ROOT_VIEW);
        import('./views').then(() => import(
            /* webpackChunkName: "optimiser-main" */
            /* webpackMode: "lazy" */
            './js/controllers/main'
        ))

        break;
    case 'ForgotPassword':
        /*new Set ovveride issue */
        $('head').append(`<script defer src="https://www.google.com/recaptcha/api.js?render=${_constantClient.GOOGLE_SITE_KEY}"></script>`);
        $('#optimiser').replaceWith(_views.noAuth.FORGOT_PASSWORD)
        import(
            /* webpackChunkName: "optimiser-forgot-password" */
            /* webpackMode: "lazy" */
            './js/controllers/no-auth/forgot-password'
        );
        break;
    case 'ForgotUsername':
         /*new Set ovveride issue */
        $('head').append(`<script defer src="https://www.google.com/recaptcha/api.js?render=${_constantClient.GOOGLE_SITE_KEY}"></script>`);
        $('#optimiser').replaceWith(_views.noAuth.FORGOT_USERNAME)
        import(
            /* webpackChunkName: "optimiser-forgot-password" */
            /* webpackMode: "lazy" */
            './js/controllers/no-auth/login'
        );
        break;
    case 'Login':
         /*new Set ovveride issue */
        $('head').append(`<script defer src="https://www.google.com/recaptcha/api.js?render=${_constantClient.GOOGLE_SITE_KEY}"></script>`);
        $('#optimiser').replaceWith(_views.noAuth.LOGIN)
        import(
            /* webpackChunkName: "optimiser-login" */
            /* webpackMode: "lazy" */
            './js/controllers/no-auth/login'
        );
        break;
    case 'CheckEmail':
        $('#optimiser').replaceWith(_views.noAuth.CHECK_EMAIL);
        import(
            /* webpackChunkName: "optimiser-check-email" */
            /* webpackMode: "lazy" */
            './js/controllers/no-auth/check-email'
        );
        break;
    case 'SetPassword':
         /*new Set ovveride issue */
        $('head').append(`<script defer src="https://www.google.com/recaptcha/api.js?render=${_constantClient.GOOGLE_SITE_KEY}"></script>`);
        $('#optimiser').replaceWith(_views.noAuth.SET_PASSWORD);
        import(
            /* webpackChunkName: "optimiser-set-password" */
            /* webpackMode: "lazy" */
            './js/controllers/no-auth/set-password'
        );
        break;
    case 'DriveShare':
        import(
            /* webpackChunkName: "optimiser-drive-share" */
            /* webpackMode: "lazy" */
            './js/controllers/drive/external-drive-share'
        );
        $('#optimiser').replaceWith(_views.noAuth.EXTERNAL_DRIVE_SHARE);
        break;
    case 'Unsubscribe':
        // QPC-7016 FIX FOR MARKETING UNSUBSCRIBE LINK
        import(
            /* webpackChunkName: "unsubscribe" */
            /* webpackMode: "lazy" */
            './js/controllers/marketing/unsubscribe'
        );
        $('#optimiser').replaceWith(`<div class='unsubscribe-page'>
        </div>`);
        break;
}

RegisterSW();